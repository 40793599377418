/*---------->HEADER------------*/
.navBar {
  background-color: #2B3A3F;
  border-bottom: 8px solid #f26250;
  color: #fff;
}

.navBar a {
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.navBar a:hover {
  color: #f26250;
}

.elemNav {
  margin-left: -20% !important;
  padding-top: 2%;
}

.elemNava {
  margin-left: 0 !important; /* Cambiado a 0 para centrar en pantallas pequeñas */
  text-align: center;
}

.inicio {
  text-decoration: none;
  color: black;
  padding-bottom: -12% !important;
}

/*---------->Footer------------*/
.footer {
  background-color: #2B3A3F;
  margin-top: 2%;
  text-align: center;
  padding: 20px 0;
  color: #C1C1C1;
  border-bottom: 8px solid #2382D8;
}

.footer p {
  line-height: 12px;
  margin: 16px 0 8px 0;
}

/*---------->EVENTO PRINCIPAL------------*/
.principal {
  display: grid;
  justify-content: center;
  padding: 20px;
}

.card_principal {
  margin-top: 2%;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 10px 10px -6px rgba(0, 0, 0, 0.50);
  border: none;
  border-bottom: 3px solid #fff;
  position: relative;
  overflow: hidden;
}

.principal-img {
  border-radius: 10px;
  width: 100%;
  transition: opacity 0.5s ease-in-out;
  display: block;
}

.card_principal:hover .principal-img {
  opacity: 0.12;
  cursor: pointer;
}

.border {
  margin-top: 5%;
  text-align: left;
  padding: 10px;
  padding-left: 16px;
  background-color: #fff;
  opacity: 1;
  color: #3A3A3A;
  font-size: 1.4em;
  font-weight: 600;
  border: none !important;
  border-left: 8px solid #f26250 !important;
}

.btn-pri {
  position: absolute;
  margin-top: 10%;
  top: 60%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  width: 80%;
  color: #fff;
  background-color: #f26250;
  border-color: #f26250;
  border-radius: 8px;
}

.card_principal:hover .btn-pri {
  opacity: 1;
  background-color: #f26250;
  border-color: #f26250;
}

.titulo_principal {
  font-size: medium;
  text-align: center;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.8s;
  color: #282c34;
}

.card_principal:hover .titulo_principal {
  opacity: 1;
}

.principal-img-movil {
  display: none;
}
/*---------->EVENTO SECUNDARIOS <------------*/
.secundario {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.sec {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 53%;
  margin: 0 auto;
}

.card-sec {
  border-radius: 10px !important;
  box-shadow: 0px 10px 10px -6px rgba(0, 0, 0, 0.50);
  border: none;
  width: 100%;
  border-bottom: 3px solid #fff;
  position: relative; /* Para ajustar elementos hijos */
  overflow: hidden; /* Evitar desbordamiento */
}

.img-sec {
  border-radius: 10px !important;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ajustar imagen sin deformar */
  transition: opacity 0.5s ease-in-out;
  display: block;
}

.img-sec-movil{
  display: none;
}

.card-sec:hover .img-sec {
  opacity: 0.5;
  cursor: pointer;
}

.titulo_secundario {
  font-size: medium;
  font-weight: 600;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.8s;
  color: #282c34;
}

.card-sec:hover .titulo_secundario {
  opacity: 1;
}

.btn-sec {
  position: absolute;
  width: 80%;
  color: #fff;
  background-color: #f26250;
  border-color: #f26250;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.card-sec:hover .btn-sec {
  opacity: 1;
  background-color: #f26250;
  border-color: #f26250;
}

/*---------->EVENTO TERCIARIOS <------------*/

.terciario {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
}

.ter {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  width: 55%;
  margin: 0 auto;
}

.card-ter {
  border-radius: 10px !important;
  box-shadow: 0px 10px 10px -6px rgba(0, 0, 0, 0.50);
  border: none;
  width: 100%;
  height: auto;
  border-bottom: 3px solid #fff;
  position: relative;
  overflow: hidden;
}

.img-ter {
  border-radius: 10px !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease-in-out;
}

.card-ter:hover .img-ter {
  opacity: 0.5;
  cursor: pointer;
}

.titulo_terciario {
  font-size: medium;
  font-weight: 600;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.8s;
  color: #282c34;
}

.card-ter:hover .titulo_terciario {
  opacity: 1;
}

.btn-ter {
  position: absolute;
  width: 80%;
  color: #fff;
  background-color: #f26250;
  border-color: #f26250;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.card-ter:hover .btn-ter {
  opacity: 1;
  background-color: #f26250;
  border-color: #f26250;
}

/*---------->DETALLE DE EVENTO <------------*/

.card_detalle {
  width: 100%;
  margin-top: 3%;
  border: none !important;
  color: #f79d8d !important;
}

.img-detalle {
  border-radius: 10px 10px 0 0 !important;
}

.titulo_detalle_div {
  background-color: #2B3A3F;
  border-radius: 0 0 10px 10px !important;
  padding-bottom: 6px;
  border-left: 7px solid #f26250;
  color: #f79d8d !important;
}

.titulo_detalle_h3 {
  padding-top: 3%;
  margin-left: 60%;
}

.titulo_detalle {
  color: #fff;
  padding-left: 2%;
  padding-top: 1.5%;
  font-size: 1.5rem;
  border: none !important;
}

.icono-detalle {
  padding-left: 2%;
  color: #f79d8d !important;
}

.bi {
  color: #282c34;
}

.card-contacto {
  padding: 2%;
  font-size: 14px;
  border: none !important;
}

.card-contacto h4 {
  margin-bottom: 0;
}

.card-contacto h3 {
  font-size: 24px;
  margin: 2px 0 20px 0;
}

.card-principal-contacto {
  width: 25rem;
  margin-right: 5%;
  border-right: 2px solid #D5D5D5;
  border-left: none !important;
  border-top: none !important;
  border-bottom: none !important;
}

.card-principal-contacto h4,
.card-principal-direccion h4 {
  color: #f26250 !important;
}

.card-principal-descripcion {
  width: 50rem;
  margin-right: 5%;
  border: none;
}

.card-group-contacto {
  display: flex;
}

.mapa-direccion {
  width: 100%;
  border: 0;
}

.card-principal-direccion {
  padding-left: 2%;
  width: 20rem;
  border-left: 2px solid #D5D5D5;
  border-right: none !important;
  border-top: none !important;
  border-bottom: none !important;
}

.boton-comprar {
  background-color: #f26250;
  color: #fff;
  padding: 18px 25px;
  border-radius: 5px;
  margin-left: 38% !important;
  font-weight: 600;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.boton-comprar:hover {
  text-decoration: none;
  background-color: #fff;
  color: #f26250;
}

/* Estilos para pantallas medianas y pequeñas */
@media (max-width: 768px) {

  .elemNav {
    margin-left: 0 !important; 
    text-align: center;
  }
  
  .elemNava {
    margin-left: 0 !important;
    text-align: center;
  }
  
  .navBar {
    padding: 10px 0; 
  }
  
  .inicio {
    padding-bottom: 0 !important; 
  }

  .principal{
    width: 77.8%;
    grid-template-columns: repeat(1, 1fr);
    margin-left: 11.9%;
  }

  .principal-img{
    display: none;
  } 

  .btn-pri {
    margin-top: 5%;
    top: 50%;
  }

  .principal-img-movil {
    display: block;
  }

  .img-sec-movil{
    display: block;
    width: 100%;
    height: 100%
  }
  .img-sec{
    display: none;
    width: 100%;
    height: 100%
  }
  .sec {
    width: 80%;
    grid-template-columns: repeat(1, 1fr);
    padding-right: 5%;
  }
  
  .btn-sec {
    bottom: 10px;
  }
  .secundario{
    margin-top: -6% !important;
  }
  .terciario{
    margin-top: -7% !important;
  }
  .ter {
    grid-template-columns: repeat(1, 1fr);
    width: 80%;
    padding-right: 5%;
  }

  .img-ter{
    width: 100%;
    display: block;
  }

  .btn-ter {
    bottom: 10px;
  }
  
    .card-group-contacto {
      flex-direction: column;
    }
  
    .card-principal-contacto,
    .card-principal-descripcion,
    .card-principal-direccion {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
      border: none;
    }
  
    .titulo_detalle_div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
    }
  
    .titulo_detalle {
      font-size: 1.2rem;
      margin: 0;
      margin-bottom: 5px;
      width: 110%;
    }
  
    .boton-comprar {
      margin-left: 0 !important;
      text-align: left;
      font-size: 14px;
      padding: 10px 15px;
      border-radius: 5px;
      margin-right: 5px;
    }
}

/* Estilo mail  */
.name-display {
  font-size: 2em;
  color: #2c3e50; /* Un color más sobrio */
  text-align: center;
  margin-top: 20px;
  text-transform: capitalize; /* Capitalizar solo la primera letra de cada palabra */
  letter-spacing: 1px; /* Reducir el espaciado entre letras */
  font-weight: 600; /* Peso de fuente semi-negrita */
}

.price-display {
  font-size: 1.75em; /* Tamaño de fuente ligeramente más grande */
  color: #e74c3c; /* Un color más vibrante */
  text-align: center;
  margin: 15px 0; /* Ajustar margen */
  font-weight: 700; /* Peso de fuente más grueso */
}

.email-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
  padding: 30px;
  max-width: 700px;
  border: 2px solid #151a20; /* Color de borde más vibrante */
  border-radius: 12px; /* Bordes más redondeados */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Sombra más suave y difusa */
  background-color: #f9f9f9; /* Fondo más claro */
}

.email-label {
  font-size: 1.4em;
  margin-bottom: 15px;
  color: #333;
  font-weight: 600;
  text-align: center;
}

.email-input {
  padding: 12px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 6px;
  width: 100%;
  max-width: 250px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease-in-out;
}

.email-input:focus {
  border-color: #007bff;
  outline: none;
}

.email-message {
  font-size: 1.2rem; /* Tamaño de fuente más grande */
  color: #333; /* Color de texto más oscuro */
  margin-bottom: 16px; /* Espaciado inferior */
  text-align: center;
}

.error-message {
  font-size: 1.5rem; /* Tamaño de fuente más pequeño para mensajes de error */
  color: #dc3545; /* Color de texto rojo para mensajes de error */
  margin-top: 8px; /* Espaciado superior para separar del mensaje principal */
}

.successful-message {
  font-size: 1.5rem; /* Tamaño de fuente más pequeño para mensajes de error */
  color: #75dc35; /* Color de texto rojo para mensajes de error */
  margin-top: 8px; /* Espaciado superior para separar del mensaje principal */
}

.submit-button {
  padding: 12px 20px;
  font-size: 1.2em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

.code-inputs {
  display: flex;
  gap: 8px; /* Espacio entre los inputs */
}

.code-input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 24px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.price-display {

  color: #333;
  margin: 10px auto; /* Centrado horizontal con margen automático */
  border: 2px solid #151a20; /* Color de borde más vibrante */
  border-radius: 12px; /* Bordes más redondeados */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Sombra más suave y difusa */
  background-color: #f9f9f9; /* Fondo más claro */
  padding: 10px;
  max-width: 700px; /* Ancho máximo para la caja */
  text-align: center; /* Centrar el texto */
}

.quantity-container {
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.quantity-input {
  font-size: 20px;

  width: 60px;
  padding: 5px;
  font-size: 16px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}